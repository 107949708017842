.trainers_list_card_link {
    cursor: pointer;
    text-decoration: none;
}

.trainers_list_card {
    display: flex;
    gap: 7px;
    text-decoration: none;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background: #fff;
    border: 2px solid #fff;
    border-radius: 40px;
    list-style-type: none;
    margin: 0;
    padding: 35px 14px 30px 12px;
    transition: all .3s ease;
}

.trainers_list_card_right_top .single_page_body_content_title_icon img {
    width: 23px;
    height: 23px;
    top: -2px;
    position: relative;
}

.trainers_list_card_left {
    width: 101px;
    position: relative;
    text-align: left;
}

.trainers_list_card_left img {
    bottom: 0;
    height: 197px;
    position: absolute;
    width: 103px;
}

.trainers_list_card_right_top {
    display: flex;
    gap: 6px;
    margin-bottom: 17px;
    margin-top: 1px;
}

.trainers_list_card_right_center {
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 30px;
}

.pokeballs_icons {
    align-items: center;
    display: flex;
    gap: 6px;
}

.pokeballs_icons[data-bol="1"] .pokeballs_icon:first-child, .pokeballs_icons[data-bol="2"] .pokeballs_icon:nth-child(-n+2), .pokeballs_icons[data-bol="3"] .pokeballs_icon:nth-child(-n+3) {
    opacity: 1;
}

.pokeballs_icon {
    background-image: url(../../../assets/icons/base_pokeball_icon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 30px;
    opacity: .2;
    width: 30px;
}

.trainers_list_card_right_top_name {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.trainers_list_card:hover {
    border: 2px solid #ed6f2d;
    box-shadow: 0 4px 50px rgba(255, 92, 0, .3);
}

.trainers_list_card_right_center .level_or_attack {
    width: 99px;
    height: 32px;
    line-height: 15px;
}

.trainers_list_card_right_botton .copy_number {
    gap: 3px;
}

@media screen and (max-width: 880px) {
    .trainers_list_card_right_center .level_or_attack {
        padding: 4px 0px !important;
        font-size: 12px;
        line-height: 12px;
        height: 24px;
        gap: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .trainers_list_card {
        flex-direction: column;
        padding: 17px;
        border-radius: 12px;
    }

    .trainers_list_card_right_botton {
        display: none;
    }

    .trainers_list_card_left {
        width: 100%;
    }

    .trainers_list_card_left img {
        height: 100%;
        object-fit: contain;
        position: relative;
        top: 0;
        width: 100%;
    }

    .trainers_list_card_left_img {
        background-color: #f8f8f8;
        border-radius: 6px;
        font-size: 0;
        margin-bottom: 15px;
        max-height: 200px;
        overflow: hidden;
        width: 100%;
    }

    .trainers_list_card_right_top {
        justify-content: center;
    }

    .trainers_list_card_right_center {
        justify-content: center;
        margin-bottom: 0px;
    }

    .trainers_list_card_left {
        min-height: 215px;
    }
}

@media screen and (max-width: 768px){


}

@media screen and (max-width: 580px){
    .trainers_list_card_right_center {
        flex-direction: column;
    }

    .trainers_list_card_right_top_name {
        max-width: 36vw;
    }
}

@media screen and (max-width: 480px) {
    .trainers_list_card {
        max-width: calc(50vw - 18px);
    }
}